.App-logo {
  pointer-events: none;
  height: 60px;
}

.App-header {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
}

.App-header h1 {
  margin: 0px;
}

.App-header p {
  padding: 0px;
  margin: 0px;
}

.App-link {
  color: #61dafb;
}

div.chartContainer {
  width: 100%;
  height: 500px;
}

/* ----- Main Content Elements ----- */

div.App {
  min-height: 100vh;
}

div.App div.wrap {
  padding-bottom: 60px;
}

div.content {
  padding: 8px;
}

h2 {
  font-weight: normal;
}

/* ----- Footer ----- */

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
}